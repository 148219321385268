/**
 * @generated SignedSource<<74a998e5a86241a0665bd6d7e49b3a84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAssignmentInput = {
  assignmentId: string;
  clientMutationId?: string | null;
  data: AssignmentDataInput;
  setTagsInput: SetTagsInputInput;
};
export type AssignmentDataInput = {
  comment?: string | null;
  endDate: any;
  importId?: string | null;
  isExecutive: boolean;
  personRef?: string | null;
  startDate: any;
  validAssignmentRolesRef: ReadonlyArray<string>;
  weight?: number | null;
};
export type SetTagsInputInput = {
  tagNames: ReadonlyArray<string>;
  tagsRef: ReadonlyArray<string>;
};
export type EditAssignmentButton_EditMutation$variables = {
  input: EditAssignmentInput;
};
export type EditAssignmentButton_EditMutation$data = {
  readonly Scenario: {
    readonly editAssignment: {
      readonly assignment: {
        readonly " $fragmentSpreads": FragmentRefs<"EditAssignmentButton_AssignmentFragment">;
      };
      readonly update: {
        readonly scenario: {
          readonly " $fragmentSpreads": FragmentRefs<"AssignmentCard_ScenarioFragment">;
        };
      };
    } | null;
  };
};
export type EditAssignmentButton_EditMutation = {
  response: EditAssignmentButton_EditMutation$data;
  variables: EditAssignmentButton_EditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "personRef",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utilizedCost",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetedCost",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAssignmentButton_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioMutations",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditAssignmentPayload",
            "kind": "LinkedField",
            "name": "editAssignment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Assignment",
                "kind": "LinkedField",
                "name": "assignment",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditAssignmentButton_AssignmentFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentUpdate",
                "kind": "LinkedField",
                "name": "update",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "scenario",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AssignmentCard_ScenarioFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditAssignmentButton_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioMutations",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditAssignmentPayload",
            "kind": "LinkedField",
            "name": "editAssignment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Assignment",
                "kind": "LinkedField",
                "name": "assignment",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "validAssignmentRoles",
                    "plural": true,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "importId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isExecutive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "comment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagData",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentUpdate",
                "kind": "LinkedField",
                "name": "update",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "scenario",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMasterPlan",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScenarioGapDays",
                        "kind": "LinkedField",
                        "name": "gapDays",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonGapDays",
                            "kind": "LinkedField",
                            "name": "personGapDays",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "gapDays",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScenarioBudget",
                        "kind": "LinkedField",
                        "name": "budget",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectBudget",
                            "kind": "LinkedField",
                            "name": "projectBudgets",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectRef",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maximumBudget",
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssignmentBudget",
                                "kind": "LinkedField",
                                "name": "assignmentBudgets",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "assignmentRef",
                                    "storageKey": null
                                  },
                                  (v6/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "months",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScenarioUtilization",
                        "kind": "LinkedField",
                        "name": "utilization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonUtilization",
                            "kind": "LinkedField",
                            "name": "personUtilizations",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "utilizationPercentage",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "989f8c515879ba190f1cfa810ba12b27",
    "id": null,
    "metadata": {},
    "name": "EditAssignmentButton_EditMutation",
    "operationKind": "mutation",
    "text": "mutation EditAssignmentButton_EditMutation(\n  $input: EditAssignmentInput!\n) {\n  Scenario {\n    editAssignment(input: $input) {\n      assignment {\n        ...EditAssignmentButton_AssignmentFragment\n        id\n      }\n      update {\n        scenario {\n          ...AssignmentCard_ScenarioFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment AssignmentCard_ScenarioFragment on Scenario {\n  ...CheckScenarioPermissions_ScenarioFragment\n  ...personDetailsButton_ScenarioFragment\n  budget {\n    projectBudgets {\n      projectRef\n      utilizedCost\n      maximumBudget\n      budgetedCost\n      assignmentBudgets {\n        assignmentRef\n        budgetedCost\n        utilizedCost\n        months\n      }\n    }\n  }\n  utilization {\n    ...personDetailsButton_ScenarioUtilizationFragment\n  }\n}\n\nfragment CheckScenarioPermissions_ScenarioFragment on Scenario {\n  isMasterPlan\n}\n\nfragment EditAssignmentButton_AssignmentFragment on Assignment {\n  id\n  startDate\n  endDate\n  person {\n    id\n  }\n  validAssignmentRoles {\n    id\n  }\n  importId\n  isExecutive\n  comment\n  weight\n  tags {\n    id\n    data {\n      name\n      color\n    }\n  }\n}\n\nfragment personDetailsButton_ScenarioFragment on Scenario {\n  id\n  gapDays {\n    personGapDays {\n      personRef\n      gapDays\n    }\n  }\n}\n\nfragment personDetailsButton_ScenarioUtilizationFragment on ScenarioUtilization {\n  personUtilizations {\n    personRef\n    utilizationPercentage\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "faa7cc8f8ba97b84083f753ad0d40482";

export default node;
